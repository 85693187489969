import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Nav, Navbar, Container, Media } from "react-bootstrap"
import "./header.css"
import LogoImage from "../images/logo.png"

const Header = ({ siteTitle }) => (
  <Navbar className="color-nav" variant="dark" expand="lg">
    <Container>
      <Navbar.Brand color="white" href="/">
        <Media>
          <img
            style={{
              marginRight: "10px",
            }}
            alt=""
            src={LogoImage}
            width={60}
            height={60}
            className="mr-3"
            className="d-inline-block align-top"
          />
          <Media.Body>
            <h2>{siteTitle}</h2>
            <p
              style={{
                fontSize: "14px",
              }}
            >
              Importador
            </p>
          </Media.Body>
        </Media>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link href="/cocinas">Cocinas Ind.</Nav.Link>
          <Nav.Link href="/pernos">Pernos</Nav.Link>
          <Nav.Link href="/material-ferretero">Material Ferretero</Nav.Link>
          <Nav.Link href="/construccion">Construcción</Nav.Link>
          <Nav.Link href="/contacto">Contacto</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
