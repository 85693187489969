// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Nav, Navbar, Container, Media, Row } from "react-bootstrap"
import "./footer.css"

const Footer = () => (
  <div className="footer-color-nav text-center">
    Reigas © {new Date().getFullYear()}
  </div>
)

export default Footer
